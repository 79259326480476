.custom-table-classname .ant-table-row .ant-table-cell:nth-child(6) {
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.ant-table-tbody > tr > td {
    white-space: nowrap;
}

.ant-table-row {
    cursor: grab;
}
